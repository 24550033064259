/**
 * Preloads an image by creating a <link rel="preload"> tag and appending it to the document head.
 * @param url - The URL of the image to preload.
 */
export function preloadImage(url: string): void {
    if (!url) {
        return;
    }

    const link = document.createElement('link');

    link.rel = 'preload';
    link.as = 'image';
    link.href = url;

    document.head.appendChild(link);
}
